
declare var jquery: any;
declare var $: any;


namespace goBack {
	const goBackBtn: string = '[data-go-back]';

	class GoBack {
		constructor() {
			this.handleGoBack()
		}

		private handleGoBack = () => {
			const backBtn = document.querySelector(goBackBtn);
			const historyLength = history.length;
			if (historyLength >= 3) { // browser default length is 2 
				backBtn.addEventListener('click', () => {
					history.go(-1);
				});
			} else {
				backBtn.classList.add("is-hidden");
			}
		}
	}
	if ($(goBackBtn).length > 0) {
		new GoBack()
	}
}
