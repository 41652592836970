
declare var jquery: any;
declare var $: any;


namespace NewsletterModule {
	// add all the elements inside modal which you want to make focusable
	const focusableElements: string =
		'button, [href], input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])';
	const newsletterModal: HTMLElement = document.querySelector("[data-newsletter-content]"); // Modal wrapper

	class Newsletter {
		tabEvent;

		constructor() {
			this.toggleNewsletter();
		}

		private toggleNewsletter = () => {

			const newsletterToggle: string = '[data-toggle-newsletter]'
			const newsletterClose: string = '[data-close-newsletter]'
			const newsletterContent: string = '[data-newsletter-content]'
			const activeClass: string = 'is-active'
			const hiddenClass: string = 'is-hidden'

			$(newsletterToggle).on('click', () => {
				$(newsletterContent).addClass(activeClass).removeClass(hiddenClass);
				$(newsletterContent).focus();
				this.holdTabLogic();
				
				$(document).keydown((e) => {
					if (e.which === 27) {
						$(newsletterContent).removeClass(activeClass).addClass(hiddenClass);
						document.removeEventListener("keydown", this.tabEvent);
					}
					$(this).unbind(e);
				})
			});

			$(newsletterClose).on('click', () => {
				$(newsletterContent).removeClass(activeClass).addClass(hiddenClass);
				document.removeEventListener("keydown", this.tabEvent);
			});

		}

		// Keep tab inside modal
		public holdTabLogic = () => {
			const firstFocusableElement = newsletterModal.querySelectorAll(focusableElements)[0] as HTMLElement; // get first element to be focused inside modal
			const focusableContent = newsletterModal.querySelectorAll(focusableElements);
			const lastFocusableElement = focusableContent[focusableContent.length - 1] as HTMLElement; // get last element to be focused inside modal

			console.log(lastFocusableElement);

			this.tabEvent = document.addEventListener('keydown', (e) => {
				let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

				if (!isTabPressed) {
					return;
				}

				if (e.shiftKey) { // if shift key pressed for shift + tab combination
					if (document.activeElement === firstFocusableElement) {
						lastFocusableElement.focus(); // add focus for the last focusable element
						e.preventDefault();
					}
				} else { // if tab key is pressed
					if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
						firstFocusableElement.focus(); // add focus for the first focusable element
						e.preventDefault();
					}
				}
			});
			firstFocusableElement.focus();
		}

	}
	new Newsletter()
}
