
declare var jquery: any;
declare var $: any;


namespace AccordionModule {
	const accordion: string = '[data-accordion]'
	const accordionHeader: string = '[data-accordion-header]'
	const accordionItem: string = '[data-accordion-item]'
	const simpleAccordion: string = '[ data-simple-acc-header]'
	const activeClass: string = 'is-active'
	class Accordion {

		constructor() {
			this.toggleAccordion();
			this.simpleAccordion();
		}

		private toggleAccordion = () => {
			
			$(accordionHeader).on('click', (e) => {
				let target = $(e.currentTarget);
				target.toggleClass(activeClass);
				target.parent(accordionItem).toggleClass(activeClass).siblings().removeClass(activeClass)
			})
		}

		public simpleAccordion = () => {
			const accordion = document.querySelectorAll(simpleAccordion);
			for (let i = 0; i < accordion.length; i++) {
				const accordionEl = accordion[i];
				accordionEl.addEventListener("click", (e) => {
					const target = e.currentTarget as HTMLElement;
					const item = document.querySelector(`#panel-${target.id}`)
					target.getAttribute("aria-expanded") == "true" ? target.setAttribute("aria-expanded", "false") : target.setAttribute("aria-expanded", "true")
					target.classList.contains(activeClass) ? target.classList.remove(activeClass) : target.classList.add(activeClass);

					item.classList.contains(activeClass) ? item.classList.remove(activeClass) : item.classList.add(activeClass)
				});
			}

		}
	}
	if ($(accordion).length > 0) {
		new Accordion()
	}

}
