
declare var jquery: any;
declare var $: any;


namespace SkipLinkModule {

	class SkipLink {
		skipLink: string;
		skipTarget: string;
		constructor(skipLink, skipTarget) {
			this.skipLink = skipLink;
			this.skipTarget = skipTarget;
			this.skipLinkEvent();
		}

		public skipLinkEvent() {
			const link: HTMLElement = document.querySelector(this.skipLink);
			const target: HTMLElement = document.querySelector(this.skipTarget);

			if (link != null) {

				link.addEventListener("click", (e: Event) => {
					link.blur();
					target.focus({ preventScroll: true });
				})

				link.addEventListener("keydown", event => {
					if (event.keyCode === 12 || event.keyCode === 32) {
						link.blur();
						target.focus({ preventScroll: true });
					}
				});
			}

		}
	}
	new SkipLink('[data-skip-nav]', '[data-main-tag]');
	new SkipLink('[data-skip-twitter]', '[data-twitter-skipped]');
}
