
namespace LottiePlayerModule {
	const lottiePlayerAttr: string = "[data-lottie-player]";
	const hasHoverStr: string = "has-hover";
	const centerSvgMobile: string = "data-center-svg-mobile"
	const lottiePlayers = document.querySelectorAll(lottiePlayerAttr);

	class LottiePlayer {

		constructor() {
			this.setupLottiePlayers();
		}

		public setupLottiePlayers() {
			lottiePlayers.forEach((lottiePlayer) => {
				const lottie: ILottiePlayer = lottiePlayer as ILottiePlayer;
				this.initEvents(lottiePlayer as ILottiePlayer);

				if (lottie.hasAttribute(centerSvgMobile) && 992 >= window.innerWidth) {
					this.centerSvg(lottie);
				}
			})
		}

		public initEvents(player: ILottiePlayer) {
			const hasHover = player.hasAttribute(hasHoverStr);
			if (hasHover) {
				player.addEventListener("mouseover", () => {
					// Animation needs to be stopped before it can start playing again
					console.log("Mouseover");
					player.stop();
					player.play();
				});

				player.addEventListener("mouseleave", () => {
					player.stop();
				})
			}
		}

		public centerSvg(lottie: ILottiePlayer) {
			const svg = lottie.shadowRoot.querySelector("svg");
		
			svg.setAttribute("preserveAspectRatio", "xMidYMid meet");
		}

	}
	if (lottiePlayers.length > 0 && lottiePlayers != null) {
		new LottiePlayer();
	}

}
interface ILottiePlayer extends Element {
	stop(): void;
	play(): void;
	pause(): void;
	preserveAspectRatio: string;
	renderer: string;
	resize(): void;
}
