namespace IEModal {
	class IEModalModule {
		private modal: HTMLDivElement | null = document.querySelector("[data-ie-modal]");
		private close: HTMLImageElement | null = document.querySelector("[data-ie-modal-close]");
		private tabEvent;
		private KEY_ENTER = 13;
		private KEY_SPACE = 32;
		private KEY_ESCAPE = 27; 

		// The last focusable element in the modal
		private lastElement: HTMLElement | null = document.querySelector('[data-focus-last]');

		constructor() {
			this.detectIE();
			this.close?.addEventListener("click", () => {
				this.hideModal();
				this.setCookie("IE-modal", true, 30);
			});

			this.close?.addEventListener("keydown", (e) => {
				if (e.keyCode === this.KEY_SPACE) {
					e.preventDefault();
				}
				if (e.keyCode === this.KEY_SPACE || e.keyCode === this.KEY_ENTER) {
					this.hideModal();
					this.setCookie("IE-modal", true, 30);
				}
			});

			this.modal?.addEventListener("click", (e) => {
				if ((e.target as HTMLElement).hasAttribute('data-ie-modal')) {
					this.hideModal();
				}
			});

			document.addEventListener("keydown", (e) => {
				if (e.keyCode === this.KEY_ESCAPE) {
					this.close.click();
					document.removeEventListener("keydown", this.tabEvent);
				}
			});
		}

		// Toggle Modal
		private showModal = () => {
			this.modal?.classList.remove("is-hidden");
			setTimeout(() => {
				this.close.focus();
				this.holdTabFocus();
			}, 10);
		}

		private hideModal = () => {
			this.modal?.classList.add("is-hidden");
			document.removeEventListener("keydown", this.tabEvent);
		}

		/*
		* detect IE
		* returns version of IE or false, if browser is not Internet Explorer
		*/
		private detectIE = () => {
			const ua = window.navigator.userAgent;

			// Test values; Uncomment to check result …

			// IE 10
			// ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

			// IE 11
			// ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

			// Edge 12 (Spartan)
			// ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36
			// (KHTML, like Gecko) Chrome / 39.0.2171.71 Safari / 537.36 Edge / 12.0';

			// Edge 13
			// ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko)
			// Chrome / 46.0.2486.0 Safari / 537.36 Edge / 13.10586';

			const msie = ua.indexOf("MSIE ");
			if (msie > 0) {
				this.checkCookie("IE-modal");
			}

			const trident = ua.indexOf("Trident/");
			if (trident > 0) {
				this.checkCookie("IE-modal");
			}

			const edge = ua.indexOf("Edge/");
			if (edge > 0) {
				this.checkCookie("IE-modal");
			}

		}

		private setCookie = (cname: string, cvalue: boolean, exdays: number) => {
			const d = new Date();
			d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
			const expires = "expires=" + d.toUTCString();
			document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
		}

		private getCookie = (cname) => {
			const name = cname + "=";
			const ca = document.cookie.split(";");
			for (let i = 0; i < ca.length; i++) {
				let c = ca[i];
				while (c.charAt(0) === " ") {
					c = c.substring(1);
				}
				if (c.indexOf(name) === 0) {
					return c.substring(name.length, c.length);
				}
			}
			return "";
		}

		private checkCookie = (cname: string) => {
			const isSet: string = this.getCookie(cname);
			if (isSet === "") {
				this.showModal();
			}
		}

		// Keep tab inside modal
		private holdTabFocus = () => {
			this.tabEvent = this.lastElement.addEventListener('focus', (e) => {
				this.close.focus();
			});
		}
	}
	new IEModalModule()
}