
namespace BreakParagraphModule {
	const paragraphBreak: string = "[data-break-paragraph]"
	const paragraph = document.querySelectorAll(paragraphBreak);

	class BreakParagraph {
		public maxStringLength = 500;

		constructor() {
			paragraph.forEach((paragraph) => {
				this.setupParagraphBreak(paragraph as HTMLElement)
			})
		}
		setupParagraphBreak(p: HTMLElement) {
			const content = p.textContent;

			if (p.textContent.length > this.maxStringLength) {
				const subFirstStr = content.substring(0, this.maxStringLength);
				const SubRemaningStr = p.textContent.substring(this.maxStringLength);

				const lastPeriodIndex = subFirstStr.lastIndexOf(".")

				const firstString = subFirstStr.substring(0, lastPeriodIndex + 1);
				const lastString = subFirstStr.substring(lastPeriodIndex + 1) + ' ' + SubRemaningStr;

				p.innerHTML = `
				<p>${firstString}</p>

				<button data-toggle-btn="" class="btn-show-more space-bottom-4" aria-expanded="false" aria-controls="${p.dataset.breakId}-desc">
					${p.dataset.breakParagraph}
				</button>

				<p id="${p.dataset.breakId}-desc" class="show-more-section">${lastString}</p>`;
			} else {
				p.innerHTML = `<p>${content}</p>`
			}
		}
	}
	if (paragraph.length > 0) {
		new BreakParagraph()
	}
}
