const truncateContent: string = '[data-truncate]'
const truncateString: string = 'data-truncate'
const truncateValueDesktop: string = 'data-truncate-desktop'
const truncateValueMobile: string = 'data-truncate-mobile'
const mobileScreenSize: number = 576

namespace TruncateModule {
    class Truncate {
        constructor() {
            this.truncate()
            window.addEventListener("resize", this.truncate)
        }

        private truncate = () => {
            //Desktop
            if (window.innerWidth > mobileScreenSize) {

                let allContent = document.querySelectorAll(truncateContent);
                this.loopItems(allContent, truncateValueDesktop);
            }
            //Mobile
            else if (window.innerWidth < mobileScreenSize) {
                let allContent = document.querySelectorAll(truncateContent);
                this.loopItems(allContent, truncateValueMobile);
            }
        }

        private loopItems = (allContent: NodeListOf<Element>, truncateValue: string) => {
            for (let i = 0; allContent.length > i; i++) {
                let content = allContent[i];
                let lengthString: string = content.getAttribute(truncateValue);
                let length: number = +lengthString;
                let string: string = content.getAttribute(truncateString);
                content.innerHTML = this.textTruncate(string, length, "...");
            }
        }

        private textTruncate = (str: string, length: number, ending: string) => {
            if (str.length > length) {
                return str.substring(0, length - ending.length) + ending;
            } else {
                return str;
            }
        }

    }
    new Truncate()
}
