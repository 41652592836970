
declare var jquery: any;
declare var $: any;


namespace SearchModule {
	class Search {
		constructor() {
			this.search()
			this.searchStatusFokus()
		}

		private search = () => {
			const searchModule: string = '[data-search-module]'
			const searchToggle: string = '[data-search-toggle]'
			const activeClass: string = 'is-active'
			const hiddenClass: string = 'is-hidden'

			$(searchToggle).on('click', (e) => {
				let target = $(e.currentTarget)
				/*	
					Visibility has to be changed like this to 
					prevent the animation to run on page load
				*/
				if ($(searchModule).hasClass(activeClass)) {
					$(searchModule).removeClass(activeClass)
					target.children().attr("aria-expanded", "false");
					setTimeout(() => {
						$(searchModule).addClass(hiddenClass)
					}, 400)
				} else {
					$(searchModule).removeClass(hiddenClass)
					target.children().attr("aria-expanded", "true");
					setTimeout(() => {
						$(searchModule).addClass(activeClass)
					}, 100)
				}
				target.children().toggleClass(activeClass)
				
			});

			$("#coi-search").keydown((e) => {
				if (!e.currentTarget.value) {
					if (e.keyCode === 27) {
						$(searchToggle).click();
					}
				}
			});		
		}
		private searchInputFokus = () => {
			const searchToggle: string = '[data-autofocus]'
			setTimeout(() => {
				$(searchToggle).focus()
			}, 800)
		}
		private searchStatusFokus = () => {
			const searchStatus: string = '[data-search-status]'
			setTimeout(() => {
				$(searchStatus).focus()
			}, 500)
		}

	}
	new Search()
}
