declare var jquery: any;
declare var $: any;

namespace SectionModule {
	const sectionWrap: string = '[data-section-wrap]'
	const sectionWrapContent: string = '[data-section-wrap-content]'
	const sectionWrapClass: string = 'section-wrap--fullwith-bg'
	const multiSelector: string = ".umb-grid__divider h4, .umb-grid__narrow h4:first-of-type";

	class SectionControl {
		constructor() {
			if ($(sectionWrap).length > 0) {
				this.sectionControl();
			}
			//if ($(multiSelector).length > 0) {
			//	this.styleFirstHeadline();
			//}
		}
		//private styleFirstHeadline = () => {
		//	const firstItem: NodeList = document.querySelectorAll(multiSelector);
		//	const firstItemArray: HTMLElement[] = Array.prototype.slice.call(firstItem);
		//	firstItemArray.forEach((elem: HTMLElement) => {
		//		if (elem.previousElementSibling === null) {
		//			elem.style.marginTop = "8px";
		//		}
		//	});
		//}
		private sectionControl = () => {
			if ($(sectionWrapContent).hasClass('full-bg')) {
				$(sectionWrap).addClass(sectionWrapClass)
			}
		}
	}
	new SectionControl()
}
