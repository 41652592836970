
declare var jquery: any;
declare var $: any;

interface JQuery {
	slick: any;
}

namespace SliderModule {
	const mainSlickElement = $('[data-slick-slider]');
	const mainSlickElementThumb = $('[data-slick-slider-thumbnail]');
	class MainSlider {
		constructor() {
			this.mainSlickSlider();

			$(window).resize(() => {
				this.mainSlickSlider();
			});
		}
		private mainSlickSlider = () => {
			let windowWidth: number = $(window).width() || 0;
			mainSlickElement.not('.slick-initialized').slick({
				slidesToShow: 1,
				autoplay: true,
				autoplaySpeed: 6000,
				draggable: true,
				slidesToScroll: 1,
				arrows: true,
				dots: true,
				fade: true,
				speed: 1200,
				infinite: true,
				pauseOnFocus: true,
				cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
				touchThreshold: 100,
				asNavFor: '[data-slick-slider-thumbnail]',
				prevArrow: $('.slider-main__control-prev'),
				nextArrow: $('.slider-main__control-next'),
				customPaging: function (slider: any, i: number) {
					return "<span id='slick-slide-control0" + i + "'" + ">"  + (i + 1) + '/' + slider.slideCount + "</span>";
				},
				responsive: [
					{
						breakpoint: 1025, settings: {
							asNavFor: null
						}
					}
				]


			});
			if (windowWidth > 768) {
				mainSlickElementThumb.not('.slick-initialized').slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: true,
					arrows: false,
					dots: false,
					fade: true,
					speed: 1200,
					infinite: true,
					accessibility: false,
					cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
					touchThreshold: 100,
					asNavFor: '[data-slick-slider]',
				});
			}
		}

	}
	if (mainSlickElement.length > 0) {
		new MainSlider()
	}

}
