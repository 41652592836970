declare var jquery: any;
declare var $: any;


namespace SlideInModule {
	const slideSectionToggle: string = '[data-slide-section-toggle]'; // Elements that toggle the modal
	const slideSectionAppendContent: string = '[data-append-content]'; // Content to add to the modal
	const slideSectionContent: string = '[data-slide-section-content]'; // Modal content
	const backdrop: string = '[data-backdrop]'; // Modal backdrop
	const activeClass: string = 'is-active';
	const KEY_ENTER: number = 13;
	const KEY_SPACE: number = 32;	


	// add all the elements inside modal which you want to make focusable
	const focusableElements =
		'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

	const slideSection: HTMLElement = document.querySelector("[data-slide-section]"); // Modal wrapper

	class SlideIn {
		tabEvent;
		chosenElement; // The element that triggered the slideIn. Used to set focus back to it when modal closes;
		constructor() {
			this.appendContent();
			this.toggleSlideIn();
		}

		private appendContent = () => {
			const itemList: Array<HTMLElement> = Array.prototype.slice.apply($(slideSectionToggle));

			itemList.forEach((element: HTMLElement) => {
				let appendContent = $(element).siblings(slideSectionAppendContent);
				$(element).on('click', () => {
					this.chosenElement = element;
					$(slideSectionContent).html($(appendContent).html());
				})

				// Keyboard support
				$(element).keyup((e) => {
					this.chosenElement = element;
					let isSpacePressed = e.key === 'Space' || e.keyCode === KEY_SPACE;
					let isEnterPressed = e.key === 'Enter' || e.keyCode === KEY_ENTER;
					if (isSpacePressed || isEnterPressed) {
						$(slideSectionContent).html($(appendContent).html());
					} else {
						return;
					}
				})

			});
		}

		private escEvent = () => {
			$(document).keydown((e) => {
				if (e.which === 27) {
					$(slideSection).removeClass(activeClass);
					$(backdrop).removeClass(activeClass);
					document.removeEventListener("keydown", this.tabEvent);
					this.chosenElement.focus();
				}
				$(this).unbind(e);
			})
		}

		private toggleSlideIn = () => {

			$(slideSectionToggle).on('click', () => {				
				$(slideSection).addClass(activeClass);
				$(backdrop).addClass(activeClass);
				this.runSlideInLogic();
				this.escEvent();
			})

			$('[data-slide-section-close], [data-backdrop]').click((e) => {
				$(slideSection).removeClass(activeClass);
				$(backdrop).removeClass(activeClass);
				document.removeEventListener("keydown", this.tabEvent);
			})

			// Keyboard Support
			$(slideSectionToggle).keyup((e) => {
				let isSpacePressed = e.key === 'Space' || e.keyCode === KEY_SPACE;
				let isEnterPressed = e.key === 'Enter' || e.keyCode === KEY_ENTER;

				if (isSpacePressed || isEnterPressed) {
					$(slideSection).addClass(activeClass);
					$(backdrop).addClass(activeClass);
					this.runSlideInLogic();
					this.escEvent();
				} else {
					return;
				}
			})

			$('[data-slide-section-close], [data-backdrop]').keyup((e) => {
				let isSpacePressed = e.key === 'Space' || e.keyCode === KEY_SPACE;
				let isEnterPressed = e.key === 'Enter' || e.keyCode === KEY_ENTER;
				if (isSpacePressed || isEnterPressed) {
					$(slideSection).removeClass(activeClass);
					$(backdrop).removeClass(activeClass);
					this.chosenElement.focus();
					document.removeEventListener("keydown", this.tabEvent);
				} else {
					return;
				}
			})
		}

		// Keep tab inside modal
		public runSlideInLogic = () => {

			const firstFocusableElement = slideSection.querySelectorAll(focusableElements)[0] as HTMLElement; // get first element to be focused inside modal
			const focusableContent = slideSection.querySelectorAll(focusableElements);
			const lastFocusableElement = focusableContent[focusableContent.length - 1] as HTMLElement; // get last element to be focused inside modal

			this.tabEvent = document.addEventListener('keydown', (e) => {
				let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

				if (!isTabPressed) {
					return;
				}

				if (e.shiftKey) { // if shift key pressed for shift + tab combination
					if (document.activeElement === firstFocusableElement) {
						lastFocusableElement.focus(); // add focus for the last focusable element
						e.preventDefault();
					}
				} else { // if tab key is pressed
					if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
						firstFocusableElement.focus(); // add focus for the first focusable element
						e.preventDefault();
					}
				}
			});
			firstFocusableElement.focus();
		}
	}

	if ($(slideSection).length > 0) {
		new SlideIn();
	}
}