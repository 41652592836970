declare var jquery: any;
declare var $: any;

namespace ModalModule {
	const toggleVideo: string = '[data-toggle-video]'
	const iframeId: string = '[data-iframe-id]'
	const iframeTitle: string = '[data-iframe-title]'
	const modal: string = '[data-modal]'
	const modalClose: string = '[data-modal-close]'
	const modalContent: string = '[data-modal-content]'
	const activeClass: string = 'is-active'
	const hiddenClass: string = 'is-hidden'
	const KEY_ENTER = 13;
	const KEY_SPACE = 32;
	const KEY_ESCAPE = 27; 

	// The last focusable element in the modal
	const lastElement: HTMLElement = document.querySelector('[data-focus-last]');

	class Modal {
		tabEvent;
		constructor() {
			this.appendUrl()
			this.toggleModal()
		}

		private appendUrl = () => {
			$(toggleVideo).on('click', (e) => {
				this.appendVideoIframe(e);
			})

			$(toggleVideo).on('keydown', (e) => {
				if (e.keyCode === KEY_SPACE || e.keyCode === KEY_ENTER) {
					this.appendVideoIframe(e);
				}
			})
		}

		private appendVideoIframe = (e) => {
			// @ts-ignore
			let cookiesAccepted = Cookiebot.consent.marketing;
			let target = $(e.currentTarget);
			let targetId = target.find(iframeId).html();
			let targetTitle = target.find(iframeTitle).html();
			let isVimeo = /^\d+$/.test(targetId);
			let isSkyfish = target.attr("data-skyfish") as boolean;

			// Skyfish
			if (targetId != undefined && isSkyfish) {
				setTimeout(() => {
					$(modalContent).html('<iframe class="iframe-item" src="' + targetId + '" frameborder="0" title="' + targetTitle + '" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen onLoad="!function(e){try{e.style.maxWidth=&apos;100%&apos;;var n=e.width/e.height;if(0<n){var t,d;(t=function(){var t,i=e.getBoundingClientRect();t=i.width<e.width?(i.width/n).toFixed(0)+&apos;px&apos;:&apos;none&apos;,d!==t&&(e.style.maxHeight=d=t)})(),window.addEventListener(&apos;resize&apos;,t)}}catch(t){console.log(t)}}(this)"></iframe>')
				}, 400)
			}
			// YouTube
			else if (targetId != undefined && isVimeo === false) {
				if (cookiesAccepted) {
					setTimeout(() => {
						$(modalContent).html('<iframe class="iframe-item" src="https://www.youtube-nocookie.com/embed/' + targetId + '?autoplay=1' + '" frameborder="0" title="' + targetTitle + '" allowfullscreen="" allow="autoplay"></iframe>')
					}, 400)
				}
				else {
					this.toggleCookiebotModal();
				}
			}
			// Vimeo
			else if (targetId != undefined && isVimeo === true) {
				if (cookiesAccepted) {
					setTimeout(() => {
						$(modalContent).html('<iframe class="iframe-item" src="https://player.vimeo.com/video/' + targetId + '?autoplay=1' + '" frameborder="0" title="' + targetTitle + '" allow="autoplay; fullscreen" allowfullscreen style="position: absolute; left: 0; top:0; height:100%; width:100%;"></iframe>')
					}, 400)
				} else {
					this.toggleCookiebotModal();
				}
			}
		}

		private toggleCookiebotModal = () => {
			// @ts-ignore
			Cookiebot.renew();
			$(modalContent).html("<p style='color:#fff;text-align:center;font-size:24px;padding-top:50px;font-weight:bold;line-height:32px'>Du skal acceptere Marketing cookies for at se videoen. Prøv derefter igen.</p>");
		}

		private toggleModal = () => {
			// Mouse click
			$(toggleVideo).on('click', () => {
				$(modal).removeClass(hiddenClass);
				setTimeout(() => {
					$(modal).addClass(activeClass)
				}, 100);

				// Wait for the animation to be done before focusing the close button
				setTimeout(() => {
					$(":button[data-modal-close]").focus()
					this.holdTabFocus();
				}, 800);
			})

			$(modalClose).on('click', () => {
				const iframeClass: string = '.iframe-item'
				$(modal).removeClass(activeClass)
				setTimeout(() => {
					$(modal).addClass(hiddenClass);
				}, 700);
				$(iframeClass).hide()
				$(iframeClass).attr('src', '')
				document.removeEventListener("keydown", this.tabEvent);
			})

			// Keyboard click
			$(toggleVideo).on('keydown', (e) => {
				if (e.keyCode === KEY_SPACE) {
					e.preventDefault();
				}
				if (e.keyCode === KEY_SPACE || e.keyCode === KEY_ENTER) {
					$(modal).removeClass(hiddenClass);
					setTimeout(() => {
						$(modal).addClass(activeClass)
					}, 100);

					// Wait for the animation to be done before focusing the close button
					setTimeout(() => {
						$(":button[data-modal-close]").focus()
						this.holdTabFocus();
					}, 700);
				}
			})

			$(modalClose).on('keydown', (e) => {
				if (e.keyCode === KEY_SPACE) {
					e.preventDefault();
				}
				if (e.keyCode === KEY_SPACE || e.keyCode === KEY_ENTER) {
					const iframeClass: string = '.iframe-item'
					$(modal).removeClass(activeClass)
					setTimeout(() => {
						$(modal).addClass(hiddenClass);
					}, 700);
					$(iframeClass).hide()
					$(iframeClass).attr('src', '')
					$(toggleVideo).focus();
					document.removeEventListener("keydown", this.tabEvent);
				}
				
			})

			$(modal).on("keydown", (e) => {
				if (e.keyCode === KEY_ESCAPE) {
					$(modalClose).click();
					$(toggleVideo).focus();
					document.removeEventListener("keydown", this.tabEvent);
				}
			});
		}

		// Keep tab inside modal
		public holdTabFocus = () => {
			this.tabEvent = lastElement.addEventListener('focus', (e) => {
				$(modalClose).focus();
			});
		}
	}
	if ($(modal).length > 0) {
		new Modal()
	}
}
