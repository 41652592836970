$(document).ready(function() {
	//@ts-ignore
	var swiper = new Swiper("[data-swiper-container]", {
		slidesPerView: 2,
		scrollbar: {
			el: ".swiper-scrollbar",
			hide: false,
			draggable: true,
		},
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		breakpoints: {
			400: {
				slidesPerView: 1.25,
			},
		},
	});
	$(function() {
		//@ts-ignore
		objectFitImages();
	});

	// Swiper mini
	//@ts-ignore
	var swiperMini = new Swiper("[data-swiper-mini]", {
		slidesPerView: 3,
		scrollbar: {
			el: ".swiper-scrollbar",
			hide: false,
			draggable: true,
		},
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		breakpoints: {
			380: {
				slidesPerView: 1,
			},
		},
	});
	$(function() {
		//@ts-ignore
		objectFitImages();
	});
});
