declare var jquery: any;
declare var $: any;


namespace HeaderModule {
	class Header {
		constructor() {
			this.toggleMobileMenu()
			this.changeIconNordicTemplate()
			this.setNavHeight()
		}
		private toggleMobileMenu = () => {
			const toggleMobileMenu: string = '[data-toggle-mobile-menu]'
			const toggleMenu: string = '[data-toggle-menu]'
			const contentWrap: string = '.content - wrap'
			const closeMenu: string = '[data-close-menu]'
			const toggleMenuContainer: string = '[data-mobile-menu-container]'
			const activeClass: string = 'is-active'
		
			$(toggleMobileMenu).on('click', (e) => {
				let target = $(e.currentTarget)
				if (!target.hasClass(activeClass)) {
					// Mark button as closed
					$(toggleMobileMenu).removeClass(activeClass)
					// Make sure all other accordions are closed
					$(toggleMobileMenu).next().removeClass(activeClass)
					// Mark the button as open
					target.addClass(activeClass)
					target.attr("aria-expanded", "true");
					// Open the selected accordion
					target.next().removeClass("hide")
					target.next().addClass(activeClass)
				} else {
					target.removeClass(activeClass)
					target.next().addClass("hide")
					target.next().removeClass(activeClass)
					target.attr("aria-expanded", "false");
				}
			});

			$(toggleMenu).on('click', (e) => {
				$(toggleMenuContainer).addClass(activeClass)
				$(contentWrap).addClass(activeClass)
				$(toggleMenuContainer).removeClass("hide-on-mobile");
				$('[data-sidebar-mobile-toggle]').removeClass(activeClass)
				// Set focus on the close button for better usability
				$(closeMenu).focus()
			});

			$(closeMenu).on('click', (e) => {
				$(toggleMenuContainer).removeClass(activeClass)
				$(contentWrap).removeClass(activeClass)
				// Set focus on the toggle button for better usability
				$(toggleMenu).focus()
				$(toggleMenuContainer).addClass("hide-on-mobile");
			});
		}

		private setNavHeight = () => {
			const contentContainer: string = '.content-wrap';
			const headerContainer: string = '.header';
			const contentContainerClass: string = 'content-wrap--extraheight';
			const headerContainerClass: string = 'header--extraheight';


			let contentEl = $(contentContainer);
			let nav_width = $('#nav-container').width() - 30;
			let nav_width_og = $('.header__menu-list').width();
			let navTrueWidth = 30

			// If page is a custom page type and not wrapped by content-wrap class 
			// the replacement class for it here 
			if (contentEl.length == 0) {
				contentEl = $('.innoTest__content-wrapper');
			}

			// 2 is the width between each link item 
			$('.header__menu-list').children('.header__menu-list-item').each(function () {
				navTrueWidth = navTrueWidth + $(this).outerWidth() - 2;
			});

			function setNavigationHeight() {
				nav_width = $('#nav-container').width();

				// Only if large than desktop
				if (window.innerWidth > 1024) {
					// Is nav larger than the true width of navigation items
					if (nav_width > (navTrueWidth - 1)) {
						contentEl.removeClass(contentContainerClass);
						$(headerContainer).removeClass(headerContainerClass)
					}

					// Is nav width larger or equal to original load with of nav and true width 
					// is larger than current nav width
					if (nav_width >= nav_width_og && (navTrueWidth + 1) > nav_width) {
						contentEl.addClass(contentContainerClass);
						$(headerContainer).addClass(headerContainerClass)
					}

					// Always add class when true
					if (nav_width < navTrueWidth) {
						contentEl.addClass(contentContainerClass);
						$(headerContainer).addClass(headerContainerClass)
					}

				} else {
					// remove class so current mobile styling takes over
					contentEl.removeClass(contentContainerClass);
					$(headerContainer).removeClass(headerContainerClass)
				}
			}

			let doit;
			window.addEventListener('resize', function (event) {
				clearTimeout(doit);
				doit = setTimeout(setNavigationHeight, 100);
			});

			setNavigationHeight();
		}

		private changeIconNordicTemplate = () => {
			const template: string = document.body.getAttribute("data-template");
			const iconLink: string = "/Content/icons/cancel.svg";
			const imgElement: any = document.getElementsByClassName("header__mobile-top")[0].getElementsByTagName("button")[0].getElementsByTagName("img")[0];

			if (template === "Nordic") {
				imgElement.src = iconLink;
			}
		}

	}
	new Header()
}
