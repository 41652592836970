
namespace ToggleBtnModule {
	const toggleBtnData: string = "[data-toggle-btn]";
	const toggleSection: string = "[data-toggle-section]";
	const activeClass: string = "is-active"
	const toggleBtnHtml = document.querySelectorAll(toggleBtnData);

	class toggleBtn {
		constructor() {
			this.toggleItem();
		}

		public toggleItem = () => {
			for (let i = 0; i < toggleBtnHtml.length; i++) {
				const btn = toggleBtnHtml[i];
				btn.addEventListener("click", (e) => {
					const btn = e.currentTarget as HTMLElement;
					const btnActive = btn.classList.contains(activeClass);

					if (btnActive) {
						btn.classList.remove(activeClass);
						btn.nextElementSibling.classList.remove(activeClass);
						btn.setAttribute("aria-expanded", "false");
					} else {
						btn.classList.add(activeClass);
						btn.nextElementSibling.classList.add(activeClass);
						btn.setAttribute("aria-expanded", "true");
					}
				});
			}
		}
	}
	if (toggleBtnHtml.length > 0) {
		new toggleBtn()
	}
}
