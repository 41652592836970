
declare var jquery: any;
declare var $: any;


namespace FilterSlideInModule {
	const filterButton: string = '[data-sidebar-mobile-toggle-btn]'
	const filterContent: string = '[data-sidebar-mobile-toggle]'
	const filterButtonClose: string = '[data-sidebar-mobile-toggle-close]'
	const activeClass: string = 'is-active'
	class FilterToggle {

		constructor() {
			this.toggleAccordion()
		}

		private toggleAccordion = () => {
		
			$(filterButton).on('click', () => {
				$(filterContent).addClass(activeClass)
			});
			$(filterButtonClose).on('click', () => {
				$(filterContent).removeClass(activeClass)
			});
		}

	}
	if ($(filterContent).length > 0) {
		new FilterToggle()
	}

}
