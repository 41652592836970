
namespace RemoveOutlineModule {
	export class RemoveOutline {
		public removeClass: string = "remove-outline";
		constructor() {
			this.initRemoveOnClick();
			this.initClearOnKeyDown();
		}
		public initRemoveOnClick() {
			const _this = this;
			document.body.addEventListener("mousedown", (event) => {
				_this.mouseEvent(event, _this);
			});
			document.body.addEventListener("touchstart", (event) => {
				_this.mouseEvent(event, _this);
			});
		}
		public mouseEvent(event: Event, _this: this) {
			document.body.classList.add(_this.removeClass);
			if (
				event.target instanceof HTMLInputElement ||
				event.target instanceof HTMLTextAreaElement
			) {
				event.target.classList.add(_this.removeClass);
			}
		}
		public initClearOnKeyDown() {
			document.body.addEventListener("keydown", () => {
				document.body.classList.remove(this.removeClass);
			});
		}
	}
	new RemoveOutline();
}
