
declare var jquery: any;
declare var $: any;


namespace BackdropModule {
	const mainBackdrop: string = '[data-main-backdrop]'
	const hiddenClass: string = 'is-hidden'
	class Backdrop {

		constructor() {
			this.toggleAccordion()
		}
		private toggleAccordion = () => {
			$(mainBackdrop).addClass(hiddenClass)
		}

	}
	new Backdrop()
}
