
declare var jquery: any;
declare var $: any;


namespace SpreadwizardModule {
	class Spreadwizard {
		constructor() {
			this.search()
		}

		private search = () => {
			const textContent: string = '[data-text-content]'
			const textToggle: string = '[data-text-toggle]'
			const activeClass: string = 'is-active'
			const openClass: string = 'open'

			$(textToggle).on('click', () => {
				$(textToggle).toggleClass(activeClass);

				$(textToggle).attr('aria-expanded', function (i, attr) {
					return attr == 'true' ? 'false' : 'true';
				});

				$(textContent).toggleClass(openClass);
			});
		}

	}
	new Spreadwizard()
}
